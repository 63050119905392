body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
  color: #333;
}
p {
  color: #666;
  line-height: 1.6;
}
.image-container {
  text-align: center;
  margin: 20px 0;
}
.image-container img {
  max-width: 100%;
  height: auto;
}
